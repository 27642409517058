import { Button, Flex, Form, Modal, type ModalProps, Row } from 'antd';
import { message } from 'antd';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAdminCompanyController_updateOneById } from '@api-client/generated/AdminCompanyController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { CompanyDetailsWrapperFormControl } from '@entities';

import * as S from './styled';

type CompanyDetailsPersonEditProps = {
  company: Schemas.AdminCompanyDto;
  onCancel: () => void;
  onAfterEdit: () => void;
} & ModalProps;

const CompanyDetailsPersonEdit: FC<CompanyDetailsPersonEditProps> = ({
  company,
  open,
  onAfterEdit,
  onCancel,
  ...rest
}) => {
  const { id: companyId } = useParams();

  const [form] = Form.useForm();

  const { mutate: updateCompany, isPending: loading } =
    useAdminCompanyController_updateOneById();

  useEffect(
    () => {
      if (company) {
        form.setFieldsValue(company);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: Schemas.AdminCompanyDto) => {
    updateCompany(
      {
        parameter: {
          id: companyId!,
        },
        requestBody: {
          ...company,
          details: {
            ...company.details,
            ...values,
          },
        },
      },
      {
        onSuccess: () => {
          message.success('Company information has been successfully updated');

          onAfterEdit();
          handleCancel();
        },
      }
    );
  };

  return (
    <Modal
      width={575}
      open={open}
      title="Edit contact person"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <S.Card>
          <Row gutter={[12, 0]}>
            <CompanyDetailsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'First name',
                name: ['contactPerson', 'firstName'],
                rules: [{ required: true }],
              }}
            />

            <CompanyDetailsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'Last name',
                name: ['contactPerson', 'lastName'],
                rules: [{ required: true }],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <CompanyDetailsWrapperFormControl
            type="input"
            form={{
              label: 'Email',
              name: ['contactPerson', 'email'],
              rules: [{ type: 'email' }],
            }}
          />

          <CompanyDetailsWrapperFormControl
            type="input"
            form={{
              label: 'Phone',
              name: ['contactPerson', 'phone'],
            }}
          />
        </S.Card>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel} size="large">
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Save
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </Modal>
  );
};

export default CompanyDetailsPersonEdit;
