import { Layout } from 'antd';
import { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAdminUserController_getCurrentUser } from '@api-client/generated/AdminUserController/getCurrentUser';
import { accessMapping } from '@constants';
import { Header, NotFound, Sidebar } from '@entities';
import AccessDenied from '@entities/AccessDenied';
import { useAccount } from '@hooks';

import * as S from './styled';

const LayoutProtected: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLogged, account, role, setAccount } = useAccount();
  const { data: user, isPending: loading } =
    useAdminUserController_getCurrentUser();

  useEffect(
    () => {
      if (user && !account) {
        setAccount(user);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, account]
  );

  useEffect(
    () => {
      if (!isLogged) {
        navigate('/login');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogged]
  );

  if (!user || loading || !role) {
    return null;
  }

  return (
    <S.Container>
      <Sidebar />

      <Layout>
        <Header />

        <S.Content>
          {accessMapping[role as 'kyc' | 'admin' | 'accountant'].some((route) =>
            pathname.includes(route)
          ) ? (
            <Outlet />
          ) : (
            <>
              {Array.from(new Set(Object.values(accessMapping).flat())).some(
                (route) => '/' + route === pathname
              ) ? (
                <AccessDenied />
              ) : (
                <NotFound />
              )}
            </>
          )}
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default LayoutProtected;
