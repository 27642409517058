import { FC } from 'react';
import { Global, css } from '@emotion/react';

import { colors } from '@theme';

export const GlobalStyles: FC = () => (
  <Global
    styles={css`
      #root {
        min-height: 100vh;
      }

      .ui-btn {
        &.ui-btn-default {
          border-color: ${colors.primary};
          border-width: 2px;

          &.ui-btn-background-ghost {
            color: ${colors.primary};
            display: flex;
            align-items: center;
          }
        }

        &.ui-btn-secondary {
          background-color: ${colors.bgGrey};
          color: ${colors.text400};

          &:hover {
            background-color: ${colors.primary};
            color: ${colors.white};
          }
        }

        &.ui-btn-success {
          border: 2px solid ${colors.success};
          color: ${colors.success};

          &:hover {
            background-color: ${colors.success};
            color: ${colors.white};
          }
        }
      }

      .ui-tag {
        padding-inline: 10px;
        font-weight: 600;
      }

      .ui-table {
        &-cell {
          &:first-of-type {
            &:after {
              content: '';
              display: inline-block;
              vertical-align: middle;
              min-height: 42px;
            }
          }
        }
      }

      .ui-select {
        min-width: 88px;

        .ui-select-selection-item {
          font-weight: 500;
        }

        .ui-select-arrow {
          color: ${colors.blackSecondary};
        }
      }

      .ui-select-dropdown {
        &.popup-hidden {
          display: none;
        }

        &.ui-select-multiple-statuses {
          .ui-select-item {
            background-color: transparent;
          }
        }

        &.ui-select-single-statuses {
          padding-block: 8px;

          .ui-select-item {
            background-color: transparent;
            padding: 8px 16px;
          }

          .ui-select-item-option-content {
            & > span {
              width: 100%;
            }
          }

          .ui-checkbox-wrapper {
            .ui-checkbox-inner {
              width: 24px;
              height: 24px;

              &:after {
                width: 6px;
                height: 10px;
                inset-inline-start: 30%;
              }
            }
          }
        }
      }

      .categories-select-dropdown {
        .ui-select-item-option-state {
          display: none;
        }
      }

      .ui-breadcrumb {
        .ui-breadcrumb-link {
          font-weight: 500;
        }
      }

      .ui-segmented {
        padding: 0;

        .ui-segmented-item {
          font-weight: 600;
          text-transform: uppercase;

          &.ui-segmented-item-selected {
            box-shadow: none;
          }
        }
      }

      .ui-dropdown {
        .ui-dropdown-menu {
          .ui-dropdown-menu-item {
            font-weight: 500;
            padding: 8px;
          }
        }
      }

      .ui-modal {
        .ui-modal-header {
          margin-bottom: 20px;
        }

        .ui-modal-close {
          width: 24px;
          height: 24px;
          top: 30px;
          inset-inline-end: 30px;

          .ui-modal-close-x {
            font-size: 18px;
          }
        }

        .ui-modal-content {
          border-radius: 20px;
          padding: 30px;
        }
      }

      .ui-picker {
        &.ui-picker-large {
          padding: 9px 11px 9px;
        }
      }

      .ui-popover-legal-actions {
        .ui-popover-title {
          margin-bottom: 0;
        }
      }

      .ui-list {
        width: 100%;
      }

      .ui-pagination {
        .ui-pagination-item {
          &-active {
            a {
              color: ${colors.white};

              &:hover {
                color: ${colors.white};
              }
            }
          }
        }
      }
    `}
  />
);
