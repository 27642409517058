/* eslint-disable @typescript-eslint/no-namespace */

/* eslint-disable @typescript-eslint/no-explicit-any */
//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Schemas } from './types';

export interface RequestBody$AdminAuthController_login {
  'application/json': Schemas.AdminLoginDto;
}
export interface Response$AdminAuthController_login$Status$200 {
  'application/json': Schemas.AdminUserJwtResponseDto;
}
export interface Response$AdminUserController_getUsers$Status$200 {
  'application/json': Schemas.User[];
}
export interface Parameter$AdminUserController_updateCurrentUser {
  id: string;
}
export interface RequestBody$AdminUserController_updateCurrentUser {
  'application/json': Schemas.AdminUpdateUserDto;
}
export interface Response$AdminUserController_updateCurrentUser$Status$200 {
  'application/json': Schemas.User;
}
export interface Response$AdminUserController_getCurrentUser$Status$200 {
  'application/json': Schemas.User;
}
export interface Parameter$AdminCompanyController_findAll {
  page?: number;
  term?: string;
}
export interface Response$AdminCompanyController_findAll$Status$200 {
  'application/json': Schemas.AdminCompanyResponseDto[];
}
export interface Parameter$AdminCompanyController_findOneById {
  id: string;
}
export interface Response$AdminCompanyController_findOneById$Status$200 {
  'application/json': Schemas.AdminCompanyResponseDto;
}
export interface Parameter$AdminCompanyController_updateOneById {
  id: string;
}
export interface RequestBody$AdminCompanyController_updateOneById {
  'application/json': Schemas.AdminCompanyDto;
}
export interface Response$AdminCompanyController_updateOneById$Status$200 {
  'application/json': Schemas.AdminCompanyResponseDto;
}
export interface Parameter$AdminTransactionController_findAll {
  companyId: string;
  page?: number;
  dateFrom?: string;
  dateTo?: string;
  term?: string;
  accountId?: string;
  adminStatus?:
    | 'ready'
    | 'processing'
    | 'paused'
    | 'requested_changes'
    | 'processed';
}
export interface Response$AdminTransactionController_findAll$Status$200 {
  'application/json': Schemas.PaginatedTrasactionResponse;
}
export interface Parameter$AdminTransactionController_toCSV {
  companyId: string;
  page?: number;
  dateFrom?: string;
  dateTo?: string;
  term?: string;
  accountId?: string;
  adminStatus?:
    | 'ready'
    | 'processing'
    | 'paused'
    | 'requested_changes'
    | 'processed';
}
export interface Parameter$AdminTransactionController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$AdminTransactionController_findOneById$Status$200 {
  'application/json': Schemas.Transaction;
}
export interface Parameter$AdminTransactionController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AdminTransactionController_updateOneById {
  'application/json': Schemas.AdminUpdateTransactionDto;
}
export interface Response$AdminTransactionController_updateOneById$Status$200 {
  'application/json': Schemas.Transaction;
}
export interface Parameter$AdminDocumentController_findAll {
  companyId: string;
  perPage?: number;
  page?: number;
  type?: string;
  term?: string;
  adminStatus?:
    | 'ready'
    | 'processing'
    | 'paused'
    | 'requested_changes'
    | 'processed';
  dateFrom?: string;
  dateTo?: string;
}
export interface Response$AdminDocumentController_findAll$Status$200 {
  'application/json': Schemas.PaginatedDocumentResponse;
}
export interface Parameter$AdminDocumentController_bulkDownload {
  companyId: string;
  perPage?: number;
  page?: number;
  type?: string;
  term?: string;
  adminStatus?:
    | 'ready'
    | 'processing'
    | 'paused'
    | 'requested_changes'
    | 'processed';
  dateFrom?: string;
  dateTo?: string;
}
export interface Parameter$AdminDocumentController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$AdminDocumentController_findOneById$Status$200 {
  'application/json': Schemas.Document;
}
export interface Parameter$AdminDocumentController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AdminDocumentController_updateOneById {
  'application/json': Schemas.AdminUpdateDocumentDto;
}
export interface Response$AdminDocumentController_updateOneById$Status$200 {
  'application/json': Schemas.Document;
}
export interface Parameter$AdminDocumentController_bulkUpdate {
  companyId: string;
}
export interface RequestBody$AdminDocumentController_bulkUpdate {
  'application/json': Schemas.AdminBulkUpdateDocumentDto;
}
export interface Parameter$AdminDocumentController_runRecognition {
  companyId: string;
  id: string;
}
export interface Parameter$AdminAccountsController_getAccounts {
  companyId: string;
}
export interface Response$AdminAccountsController_getAccounts$Status$200 {
  'application/json': Schemas.Account[];
}
export interface Parameter$AdminAccountsController_syncConnection {
  companyId: string;
  id: string;
}
export interface Parameter$AdminEventController_findAll {
  companyId: string;
  entityType:
    | 'category'
    | 'company'
    | 'contact'
    | 'file'
    | 'transaction'
    | 'project'
    | 'company_file'
    | 'incorporation_kyc';
  entityId: string;
}
export interface Response$AdminEventController_findAll$Status$200 {
  'application/json': Schemas.Event[];
}
export interface Parameter$AdminIncorporationController_findOneById {
  companyId: string;
}
export interface Response$AdminIncorporationController_findOneById$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$AdminIncorporationController_updateOneById {
  companyId: string;
}
export interface RequestBody$AdminIncorporationController_updateOneById {
  'application/json': Schemas.AdminIncorporationDto;
}
export interface Response$AdminIncorporationController_updateOneById$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$AdminIncorporationController_approveKyc {
  companyId: string;
}
export interface Response$AdminIncorporationController_approveKyc$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$AdminIncorporationController_failKyc {
  companyId: string;
}
export interface Response$AdminIncorporationController_failKyc$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$AdminCompanyFileController_findAll {
  companyId: string;
  type: string;
  personId: string;
}
export interface Response$AdminCompanyFileController_findAll$Status$200 {
  'application/json': Schemas.CompanyFile[];
}
export interface Parameter$AdminCompanyFileController_create {
  companyId: string;
}
export interface RequestBody$AdminCompanyFileController_create {
  'multipart/form-data': Schemas.AdminCreateCompanyFileDto;
}
export interface Response$AdminCompanyFileController_create$Status$201 {
  'application/json': Schemas.CompanyFile;
}
export interface Parameter$AdminCompanyFileController_delete {
  companyId: string;
  id: string;
}
export interface Response$AdminCompanyFileController_delete$Status$200 {
  'application/json': Schemas.CompanyFile;
}
export interface Parameter$AdminCompanyFileController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AdminCompanyFileController_updateOneById {
  'multipart/form-data': Schemas.AdminUpdateCompanyFileDto;
}
export interface Response$AdminCompanyFileController_updateOneById$Status$200 {
  'application/json': Schemas.CompanyFile;
}
export interface Parameter$AdminOnfidoDataController_create {
  personId: string;
  companyId: string;
}
export interface Response$AdminOnfidoDataController_create$Status$201 {
  'application/json': Schemas.OnfidoData;
}
export interface Parameter$AdminTaxController_findAll {
  companyId: string;
}
export interface Response$AdminTaxController_findAll$Status$200 {
  'application/json': Schemas.Tax[];
}
export interface Parameter$AdminTaxController_create {
  companyId: string;
}
export interface RequestBody$AdminTaxController_create {
  'multipart/form-data': Schemas.AdminCreateTaxDto;
}
export interface Response$AdminTaxController_create$Status$201 {
  'application/json': Schemas.Tax;
}
export interface Parameter$AdminTaxController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$AdminTaxController_findOneById$Status$200 {
  'application/json': Schemas.Tax;
}
export interface Parameter$AdminTaxController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AdminTaxController_updateOneById {
  'application/json': Schemas.AdminCreateTaxDto;
}
export interface Response$AdminTaxController_updateOneById$Status$200 {
  'application/json': Schemas.Tax;
}
export interface Parameter$AdminTaskController_findAll {
  topic?: string;
  companyId?: string;
  onlyForUser?: boolean;
}
export interface Response$AdminTaskController_findAll$Status$200 {
  'application/json': Schemas.Task[];
}
export interface RequestBody$AdminTaskController_create {
  'application/json': Schemas.AdminTaskDto;
}
export interface Response$AdminTaskController_create$Status$201 {
  'application/json': Schemas.Task;
}
export interface Parameter$AdminTaskController_updateOneById {
  id: string;
}
export interface RequestBody$AdminTaskController_updateOneById {
  'application/json': Schemas.AdminTaskUpdateDto;
}
export interface Response$AdminTaskController_updateOneById$Status$200 {
  'application/json': Schemas.Task;
}
export interface Parameter$AdminTaskController_close {
  id: string;
}
export interface Response$AdminTaskController_close$Status$200 {
  'application/json': Schemas.Task;
}
export interface RequestBody$AdminChatMessageController_create {
  'application/json': Schemas.ChatMessageDto;
}
export interface Response$AdminChatMessageController_create$Status$201 {
  'application/json': Schemas.ChatMessage;
}
export interface Parameter$AdminTaskFileController_create {
  companyId: string;
}
export interface RequestBody$AdminTaskFileController_create {
  'multipart/form-data': Schemas.AdminCreateTaskFileDto;
}
export interface Response$AdminTaskFileController_create$Status$201 {
  'application/json': Schemas.TaskFile;
}
export interface Parameter$AdminBillingPeriodController_findAll {
  companyId: string;
}
export interface Response$AdminBillingPeriodController_findAll$Status$200 {
  'application/json': Schemas.BillingPeriod[];
}
export interface Parameter$AdminBillingPeriodController_create {
  companyId: string;
}
export interface RequestBody$AdminBillingPeriodController_create {
  'application/json': Schemas.AdminBillingPeriodDto;
}
export interface Response$AdminBillingPeriodController_create$Status$201 {
  'application/json': Schemas.BillingPeriod;
}
export interface Parameter$AdminBillingPeriodController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$AdminBillingPeriodController_findOneById$Status$200 {
  'application/json': Schemas.BillingPeriod;
}
export interface Parameter$AdminBillingPeriodController_delete {
  companyId: string;
  id: string;
}
export interface Response$AdminBillingPeriodController_delete$Status$200 {
  'application/json': Schemas.BillingPeriod;
}
export interface Parameter$AdminBillingPeriodController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AdminBillingPeriodController_updateOneById {
  'application/json': Schemas.AdminBillingPeriodDto;
}
export interface Response$AdminBillingPeriodController_updateOneById$Status$200 {
  'application/json': Schemas.BillingPeriod;
}
export interface Parameter$AdminBobAccountController_findAll {
  companyId: string;
  page?: number;
  term?: string;
}
export interface Response$AdminBobAccountController_findAll$Status$200 {
  'application/json': Schemas.BobAccount[];
}
export interface Parameter$AdminBobDaybookController_findAll {
  companyId: string;
}
export interface Response$AdminBobDaybookController_findAll$Status$200 {
  'application/json': Schemas.BobDaybook[];
}
export interface Parameter$AdminBobVATController_findAll {
  companyId: string;
}
export interface Response$AdminBobVATController_findAll$Status$200 {
  'application/json': Schemas.BobVAT[];
}
export interface Parameter$AdminBobContactController_findAll {
  companyId: string;
  page?: number;
  term?: string;
}
export interface Response$AdminBobContactController_findAll$Status$200 {
  'application/json': Schemas.BobContact[];
}
export interface Parameter$AdminBobContactController_create {
  companyId: string;
}
export interface RequestBody$AdminBobContactController_create {
  'application/json': Schemas.AdminBobContactDto;
}
export interface Response$AdminBobContactController_create$Status$201 {
  'application/json': Schemas.BobContact;
}
export type RequestContentType$AdminAuthController_login =
  keyof RequestBody$AdminAuthController_login;
export type ResponseContentType$AdminAuthController_login =
  keyof Response$AdminAuthController_login$Status$200;
export interface Params$AdminAuthController_login {
  requestBody: RequestBody$AdminAuthController_login['application/json'];
}
export type ResponseContentType$AdminUserController_getUsers =
  keyof Response$AdminUserController_getUsers$Status$200;
export type RequestContentType$AdminUserController_updateCurrentUser =
  keyof RequestBody$AdminUserController_updateCurrentUser;
export type ResponseContentType$AdminUserController_updateCurrentUser =
  keyof Response$AdminUserController_updateCurrentUser$Status$200;
export interface Params$AdminUserController_updateCurrentUser {
  parameter: Parameter$AdminUserController_updateCurrentUser;
  requestBody: RequestBody$AdminUserController_updateCurrentUser['application/json'];
}
export type ResponseContentType$AdminUserController_getCurrentUser =
  keyof Response$AdminUserController_getCurrentUser$Status$200;
export type ResponseContentType$AdminCompanyController_findAll =
  keyof Response$AdminCompanyController_findAll$Status$200;
export interface Params$AdminCompanyController_findAll {
  parameter: Parameter$AdminCompanyController_findAll;
}
export type ResponseContentType$AdminCompanyController_findOneById =
  keyof Response$AdminCompanyController_findOneById$Status$200;
export interface Params$AdminCompanyController_findOneById {
  parameter: Parameter$AdminCompanyController_findOneById;
}
export type RequestContentType$AdminCompanyController_updateOneById =
  keyof RequestBody$AdminCompanyController_updateOneById;
export type ResponseContentType$AdminCompanyController_updateOneById =
  keyof Response$AdminCompanyController_updateOneById$Status$200;
export interface Params$AdminCompanyController_updateOneById {
  parameter: Parameter$AdminCompanyController_updateOneById;
  requestBody: RequestBody$AdminCompanyController_updateOneById['application/json'];
}
export type ResponseContentType$AdminTransactionController_findAll =
  keyof Response$AdminTransactionController_findAll$Status$200;
export interface Params$AdminTransactionController_findAll {
  parameter: Parameter$AdminTransactionController_findAll;
}
export interface Params$AdminTransactionController_toCSV {
  parameter: Parameter$AdminTransactionController_toCSV;
}
export type ResponseContentType$AdminTransactionController_findOneById =
  keyof Response$AdminTransactionController_findOneById$Status$200;
export interface Params$AdminTransactionController_findOneById {
  parameter: Parameter$AdminTransactionController_findOneById;
}
export type RequestContentType$AdminTransactionController_updateOneById =
  keyof RequestBody$AdminTransactionController_updateOneById;
export type ResponseContentType$AdminTransactionController_updateOneById =
  keyof Response$AdminTransactionController_updateOneById$Status$200;
export interface Params$AdminTransactionController_updateOneById {
  parameter: Parameter$AdminTransactionController_updateOneById;
  requestBody: RequestBody$AdminTransactionController_updateOneById['application/json'];
}
export type ResponseContentType$AdminDocumentController_findAll =
  keyof Response$AdminDocumentController_findAll$Status$200;
export interface Params$AdminDocumentController_findAll {
  parameter: Parameter$AdminDocumentController_findAll;
}
export interface Params$AdminDocumentController_bulkDownload {
  parameter: Parameter$AdminDocumentController_bulkDownload;
}
export type ResponseContentType$AdminDocumentController_findOneById =
  keyof Response$AdminDocumentController_findOneById$Status$200;
export interface Params$AdminDocumentController_findOneById {
  parameter: Parameter$AdminDocumentController_findOneById;
}
export type RequestContentType$AdminDocumentController_updateOneById =
  keyof RequestBody$AdminDocumentController_updateOneById;
export type ResponseContentType$AdminDocumentController_updateOneById =
  keyof Response$AdminDocumentController_updateOneById$Status$200;
export interface Params$AdminDocumentController_updateOneById {
  parameter: Parameter$AdminDocumentController_updateOneById;
  requestBody: RequestBody$AdminDocumentController_updateOneById['application/json'];
}
export type RequestContentType$AdminDocumentController_bulkUpdate =
  keyof RequestBody$AdminDocumentController_bulkUpdate;
export interface Params$AdminDocumentController_bulkUpdate {
  parameter: Parameter$AdminDocumentController_bulkUpdate;
  requestBody: RequestBody$AdminDocumentController_bulkUpdate['application/json'];
}
export interface Params$AdminDocumentController_runRecognition {
  parameter: Parameter$AdminDocumentController_runRecognition;
}
export type ResponseContentType$AdminAccountsController_getAccounts =
  keyof Response$AdminAccountsController_getAccounts$Status$200;
export interface Params$AdminAccountsController_getAccounts {
  parameter: Parameter$AdminAccountsController_getAccounts;
}
export interface Params$AdminAccountsController_syncConnection {
  parameter: Parameter$AdminAccountsController_syncConnection;
}
export type ResponseContentType$AdminEventController_findAll =
  keyof Response$AdminEventController_findAll$Status$200;
export interface Params$AdminEventController_findAll {
  parameter: Parameter$AdminEventController_findAll;
}
export type ResponseContentType$AdminIncorporationController_findOneById =
  keyof Response$AdminIncorporationController_findOneById$Status$200;
export interface Params$AdminIncorporationController_findOneById {
  parameter: Parameter$AdminIncorporationController_findOneById;
}
export type RequestContentType$AdminIncorporationController_updateOneById =
  keyof RequestBody$AdminIncorporationController_updateOneById;
export type ResponseContentType$AdminIncorporationController_updateOneById =
  keyof Response$AdminIncorporationController_updateOneById$Status$200;
export interface Params$AdminIncorporationController_updateOneById {
  parameter: Parameter$AdminIncorporationController_updateOneById;
  requestBody: RequestBody$AdminIncorporationController_updateOneById['application/json'];
}
export type ResponseContentType$AdminIncorporationController_approveKyc =
  keyof Response$AdminIncorporationController_approveKyc$Status$200;
export interface Params$AdminIncorporationController_approveKyc {
  parameter: Parameter$AdminIncorporationController_approveKyc;
}
export type ResponseContentType$AdminIncorporationController_failKyc =
  keyof Response$AdminIncorporationController_failKyc$Status$200;
export interface Params$AdminIncorporationController_failKyc {
  parameter: Parameter$AdminIncorporationController_failKyc;
}
export type ResponseContentType$AdminCompanyFileController_findAll =
  keyof Response$AdminCompanyFileController_findAll$Status$200;
export interface Params$AdminCompanyFileController_findAll {
  parameter: Parameter$AdminCompanyFileController_findAll;
}
export type RequestContentType$AdminCompanyFileController_create =
  keyof RequestBody$AdminCompanyFileController_create;
export type ResponseContentType$AdminCompanyFileController_create =
  keyof Response$AdminCompanyFileController_create$Status$201;
export interface Params$AdminCompanyFileController_create {
  parameter: Parameter$AdminCompanyFileController_create;
  requestBody: RequestBody$AdminCompanyFileController_create['multipart/form-data'];
}
export type ResponseContentType$AdminCompanyFileController_delete =
  keyof Response$AdminCompanyFileController_delete$Status$200;
export interface Params$AdminCompanyFileController_delete {
  parameter: Parameter$AdminCompanyFileController_delete;
}
export type RequestContentType$AdminCompanyFileController_updateOneById =
  keyof RequestBody$AdminCompanyFileController_updateOneById;
export type ResponseContentType$AdminCompanyFileController_updateOneById =
  keyof Response$AdminCompanyFileController_updateOneById$Status$200;
export interface Params$AdminCompanyFileController_updateOneById {
  parameter: Parameter$AdminCompanyFileController_updateOneById;
  requestBody: RequestBody$AdminCompanyFileController_updateOneById['multipart/form-data'];
}
export type ResponseContentType$AdminOnfidoDataController_create =
  keyof Response$AdminOnfidoDataController_create$Status$201;
export interface Params$AdminOnfidoDataController_create {
  parameter: Parameter$AdminOnfidoDataController_create;
}
export type ResponseContentType$AdminTaxController_findAll =
  keyof Response$AdminTaxController_findAll$Status$200;
export interface Params$AdminTaxController_findAll {
  parameter: Parameter$AdminTaxController_findAll;
}
export type RequestContentType$AdminTaxController_create =
  keyof RequestBody$AdminTaxController_create;
export type ResponseContentType$AdminTaxController_create =
  keyof Response$AdminTaxController_create$Status$201;
export interface Params$AdminTaxController_create {
  parameter: Parameter$AdminTaxController_create;
  requestBody: RequestBody$AdminTaxController_create['multipart/form-data'];
}
export type ResponseContentType$AdminTaxController_findOneById =
  keyof Response$AdminTaxController_findOneById$Status$200;
export interface Params$AdminTaxController_findOneById {
  parameter: Parameter$AdminTaxController_findOneById;
}
export type RequestContentType$AdminTaxController_updateOneById =
  keyof RequestBody$AdminTaxController_updateOneById;
export type ResponseContentType$AdminTaxController_updateOneById =
  keyof Response$AdminTaxController_updateOneById$Status$200;
export interface Params$AdminTaxController_updateOneById {
  parameter: Parameter$AdminTaxController_updateOneById;
  requestBody: RequestBody$AdminTaxController_updateOneById['application/json'];
}
export type ResponseContentType$AdminTaskController_findAll =
  keyof Response$AdminTaskController_findAll$Status$200;
export interface Params$AdminTaskController_findAll {
  parameter: Parameter$AdminTaskController_findAll;
}
export type RequestContentType$AdminTaskController_create =
  keyof RequestBody$AdminTaskController_create;
export type ResponseContentType$AdminTaskController_create =
  keyof Response$AdminTaskController_create$Status$201;
export interface Params$AdminTaskController_create {
  requestBody: RequestBody$AdminTaskController_create['application/json'];
}
export type RequestContentType$AdminTaskController_updateOneById =
  keyof RequestBody$AdminTaskController_updateOneById;
export type ResponseContentType$AdminTaskController_updateOneById =
  keyof Response$AdminTaskController_updateOneById$Status$200;
export interface Params$AdminTaskController_updateOneById {
  parameter: Parameter$AdminTaskController_updateOneById;
  requestBody: RequestBody$AdminTaskController_updateOneById['application/json'];
}
export type ResponseContentType$AdminTaskController_close =
  keyof Response$AdminTaskController_close$Status$200;
export interface Params$AdminTaskController_close {
  parameter: Parameter$AdminTaskController_close;
}
export type RequestContentType$AdminChatMessageController_create =
  keyof RequestBody$AdminChatMessageController_create;
export type ResponseContentType$AdminChatMessageController_create =
  keyof Response$AdminChatMessageController_create$Status$201;
export interface Params$AdminChatMessageController_create {
  requestBody: RequestBody$AdminChatMessageController_create['application/json'];
}
export type RequestContentType$AdminTaskFileController_create =
  keyof RequestBody$AdminTaskFileController_create;
export type ResponseContentType$AdminTaskFileController_create =
  keyof Response$AdminTaskFileController_create$Status$201;
export interface Params$AdminTaskFileController_create {
  parameter: Parameter$AdminTaskFileController_create;
  requestBody: RequestBody$AdminTaskFileController_create['multipart/form-data'];
}
export type ResponseContentType$AdminBillingPeriodController_findAll =
  keyof Response$AdminBillingPeriodController_findAll$Status$200;
export interface Params$AdminBillingPeriodController_findAll {
  parameter: Parameter$AdminBillingPeriodController_findAll;
}
export type RequestContentType$AdminBillingPeriodController_create =
  keyof RequestBody$AdminBillingPeriodController_create;
export type ResponseContentType$AdminBillingPeriodController_create =
  keyof Response$AdminBillingPeriodController_create$Status$201;
export interface Params$AdminBillingPeriodController_create {
  parameter: Parameter$AdminBillingPeriodController_create;
  requestBody: RequestBody$AdminBillingPeriodController_create['application/json'];
}
export type ResponseContentType$AdminBillingPeriodController_findOneById =
  keyof Response$AdminBillingPeriodController_findOneById$Status$200;
export interface Params$AdminBillingPeriodController_findOneById {
  parameter: Parameter$AdminBillingPeriodController_findOneById;
}
export type ResponseContentType$AdminBillingPeriodController_delete =
  keyof Response$AdminBillingPeriodController_delete$Status$200;
export interface Params$AdminBillingPeriodController_delete {
  parameter: Parameter$AdminBillingPeriodController_delete;
}
export type RequestContentType$AdminBillingPeriodController_updateOneById =
  keyof RequestBody$AdminBillingPeriodController_updateOneById;
export type ResponseContentType$AdminBillingPeriodController_updateOneById =
  keyof Response$AdminBillingPeriodController_updateOneById$Status$200;
export interface Params$AdminBillingPeriodController_updateOneById {
  parameter: Parameter$AdminBillingPeriodController_updateOneById;
  requestBody: RequestBody$AdminBillingPeriodController_updateOneById['application/json'];
}
export type ResponseContentType$AdminBobAccountController_findAll =
  keyof Response$AdminBobAccountController_findAll$Status$200;
export interface Params$AdminBobAccountController_findAll {
  parameter: Parameter$AdminBobAccountController_findAll;
}
export type ResponseContentType$AdminBobDaybookController_findAll =
  keyof Response$AdminBobDaybookController_findAll$Status$200;
export interface Params$AdminBobDaybookController_findAll {
  parameter: Parameter$AdminBobDaybookController_findAll;
}
export type ResponseContentType$AdminBobVATController_findAll =
  keyof Response$AdminBobVATController_findAll$Status$200;
export interface Params$AdminBobVATController_findAll {
  parameter: Parameter$AdminBobVATController_findAll;
}
export type ResponseContentType$AdminBobContactController_findAll =
  keyof Response$AdminBobContactController_findAll$Status$200;
export interface Params$AdminBobContactController_findAll {
  parameter: Parameter$AdminBobContactController_findAll;
}
export type RequestContentType$AdminBobContactController_create =
  keyof RequestBody$AdminBobContactController_create;
export type ResponseContentType$AdminBobContactController_create =
  keyof Response$AdminBobContactController_create$Status$201;
export interface Params$AdminBobContactController_create {
  parameter: Parameter$AdminBobContactController_create;
  requestBody: RequestBody$AdminBobContactController_create['application/json'];
}
export type HttpMethod =
  | 'GET'
  | 'PUT'
  | 'POST'
  | 'DELETE'
  | 'OPTIONS'
  | 'HEAD'
  | 'PATCH'
  | 'TRACE';
export interface ObjectLike {
  [key: string]: any;
}
export interface QueryParameter {
  value: any;
  style?: 'form' | 'spaceDelimited' | 'pipeDelimited' | 'deepObject';
  explode: boolean;
}
export interface QueryParameters {
  [key: string]: QueryParameter;
}
export type SuccessResponses =
  | Response$AdminAuthController_login$Status$200
  | Response$AdminUserController_getUsers$Status$200
  | Response$AdminUserController_updateCurrentUser$Status$200
  | Response$AdminUserController_getCurrentUser$Status$200
  | Response$AdminCompanyController_findAll$Status$200
  | Response$AdminCompanyController_findOneById$Status$200
  | Response$AdminCompanyController_updateOneById$Status$200
  | Response$AdminTransactionController_findAll$Status$200
  | Response$AdminTransactionController_findOneById$Status$200
  | Response$AdminTransactionController_updateOneById$Status$200
  | Response$AdminDocumentController_findAll$Status$200
  | Response$AdminDocumentController_findOneById$Status$200
  | Response$AdminDocumentController_updateOneById$Status$200
  | Response$AdminAccountsController_getAccounts$Status$200
  | Response$AdminEventController_findAll$Status$200
  | Response$AdminIncorporationController_findOneById$Status$200
  | Response$AdminIncorporationController_updateOneById$Status$200
  | Response$AdminIncorporationController_approveKyc$Status$200
  | Response$AdminIncorporationController_failKyc$Status$200
  | Response$AdminCompanyFileController_findAll$Status$200
  | Response$AdminCompanyFileController_create$Status$201
  | Response$AdminCompanyFileController_delete$Status$200
  | Response$AdminCompanyFileController_updateOneById$Status$200
  | Response$AdminOnfidoDataController_create$Status$201
  | Response$AdminTaxController_findAll$Status$200
  | Response$AdminTaxController_create$Status$201
  | Response$AdminTaxController_findOneById$Status$200
  | Response$AdminTaxController_updateOneById$Status$200
  | Response$AdminTaskController_findAll$Status$200
  | Response$AdminTaskController_create$Status$201
  | Response$AdminTaskController_updateOneById$Status$200
  | Response$AdminTaskController_close$Status$200
  | Response$AdminChatMessageController_create$Status$201
  | Response$AdminTaskFileController_create$Status$201
  | Response$AdminBillingPeriodController_findAll$Status$200
  | Response$AdminBillingPeriodController_create$Status$201
  | Response$AdminBillingPeriodController_findOneById$Status$200
  | Response$AdminBillingPeriodController_delete$Status$200
  | Response$AdminBillingPeriodController_updateOneById$Status$200
  | Response$AdminBobAccountController_findAll$Status$200
  | Response$AdminBobDaybookController_findAll$Status$200
  | Response$AdminBobVATController_findAll$Status$200
  | Response$AdminBobContactController_findAll$Status$200
  | Response$AdminBobContactController_create$Status$201;
export namespace ErrorResponse {
  export type AdminAuthController_login = void;
  export type AdminUserController_getUsers = void;
  export type AdminUserController_updateCurrentUser = void;
  export type AdminUserController_getCurrentUser = void;
  export type AdminCompanyController_findAll = void;
  export type AdminCompanyController_findOneById = void;
  export type AdminCompanyController_updateOneById = void;
  export type AdminTransactionController_findAll = void;
  export type AdminTransactionController_toCSV = void;
  export type AdminTransactionController_findOneById = void;
  export type AdminTransactionController_updateOneById = void;
  export type AdminDocumentController_findAll = void;
  export type AdminDocumentController_bulkDownload = void;
  export type AdminDocumentController_findOneById = void;
  export type AdminDocumentController_updateOneById = void;
  export type AdminDocumentController_bulkUpdate = void;
  export type AdminDocumentController_runRecognition = void;
  export type AdminAccountsController_getAccounts = void;
  export type AdminAccountsController_syncConnection = void;
  export type AdminEventController_findAll = void;
  export type AdminIncorporationController_findOneById = void;
  export type AdminIncorporationController_updateOneById = void;
  export type AdminIncorporationController_approveKyc = void;
  export type AdminIncorporationController_failKyc = void;
  export type AdminCompanyFileController_findAll = void;
  export type AdminCompanyFileController_create = void;
  export type AdminCompanyFileController_delete = void;
  export type AdminCompanyFileController_updateOneById = void;
  export type AdminOnfidoDataController_create = void;
  export type AdminTaxController_findAll = void;
  export type AdminTaxController_create = void;
  export type AdminTaxController_findOneById = void;
  export type AdminTaxController_updateOneById = void;
  export type AdminTaskController_findAll = void;
  export type AdminTaskController_create = void;
  export type AdminTaskController_updateOneById = void;
  export type AdminTaskController_close = void;
  export type AdminChatMessageController_create = void;
  export type AdminTaskFileController_create = void;
  export type AdminBillingPeriodController_findAll = void;
  export type AdminBillingPeriodController_create = void;
  export type AdminBillingPeriodController_findOneById = void;
  export type AdminBillingPeriodController_delete = void;
  export type AdminBillingPeriodController_updateOneById = void;
  export type AdminBobAccountController_findAll = void;
  export type AdminBobDaybookController_findAll = void;
  export type AdminBobVATController_findAll = void;
  export type AdminBobContactController_findAll = void;
  export type AdminBobContactController_create = void;
}
export interface Encoding {
  readonly contentType?: string;
  headers?: Record<string, any>;
  readonly style?: 'form' | 'spaceDelimited' | 'pipeDelimited' | 'deepObject';
  readonly explode?: boolean;
  readonly allowReserved?: boolean;
}
export interface RequestArgs {
  readonly httpMethod: HttpMethod;
  readonly url: string;
  headers: ObjectLike | any;
  requestBody?: ObjectLike | any;
  requestBodyEncoding?: Record<string, Encoding>;
  queryParameters?: QueryParameters | undefined;
}
export interface ApiClient<RequestOption> {
  request: <T = SuccessResponses>(
    requestArgs: RequestArgs,
    options?: RequestOption
  ) => Promise<T>;
}
export const createClient = <RequestOption,>(
  apiClient: ApiClient<RequestOption>,
  baseUrl: string
) => {
  const _baseUrl = baseUrl.replace(/\/$/, '');
  return {
    /**
     * Login user
     * Login user
     * operationId: AdminAuthController_login
     * Request URI: /api/admin/auth/login
     */
    AdminAuthController_login: (
      params: Params$AdminAuthController_login,
      option?: RequestOption
    ): Promise<
      Response$AdminAuthController_login$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/auth/login`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PUT',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of users
     * Get a list of users
     * operationId: AdminUserController_getUsers
     * Request URI: /api/admin/users
     */
    AdminUserController_getUsers: (
      option?: RequestOption
    ): Promise<
      Response$AdminUserController_getUsers$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/users`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update user
     * Update user
     * operationId: AdminUserController_updateCurrentUser
     * Request URI: /api/admin/users/{id}
     */
    AdminUserController_updateCurrentUser: (
      params: Params$AdminUserController_updateCurrentUser,
      option?: RequestOption
    ): Promise<
      Response$AdminUserController_updateCurrentUser$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/users/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of users
     * Get a list of users
     * operationId: AdminUserController_getCurrentUser
     * Request URI: /api/admin/users/me
     */
    AdminUserController_getCurrentUser: (
      option?: RequestOption
    ): Promise<
      Response$AdminUserController_getCurrentUser$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/users/me`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of companies
     * Get a list of companies
     * operationId: AdminCompanyController_findAll
     * Request URI: /api/admin/companies
     */
    AdminCompanyController_findAll: (
      params: Params$AdminCompanyController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/companies`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        term: { value: params.parameter.term, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a company by its id
     * Get a company by its id
     * operationId: AdminCompanyController_findOneById
     * Request URI: /api/admin/companies/{id}
     */
    AdminCompanyController_findOneById: (
      params: Params$AdminCompanyController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/companies/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a company by its ID
     * Update a company by its ID
     * operationId: AdminCompanyController_updateOneById
     * Request URI: /api/admin/companies/{id}
     */
    AdminCompanyController_updateOneById: (
      params: Params$AdminCompanyController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/companies/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of transactions
     * Get a list of transactions
     * operationId: AdminTransactionController_findAll
     * Request URI: /api/admin/transactions
     */
    AdminTransactionController_findAll: (
      params: Params$AdminTransactionController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminTransactionController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/transactions`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        term: { value: params.parameter.term, explode: false },
        accountId: { value: params.parameter.accountId, explode: false },
        adminStatus: { value: params.parameter.adminStatus, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of transactions in csv format
     * Get a list of transactions in csv format
     * operationId: AdminTransactionController_toCSV
     * Request URI: /api/admin/transactions/csv
     */
    AdminTransactionController_toCSV: (
      params: Params$AdminTransactionController_toCSV,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/admin/transactions/csv`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        term: { value: params.parameter.term, explode: false },
        accountId: { value: params.parameter.accountId, explode: false },
        adminStatus: { value: params.parameter.adminStatus, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a transaction by its id
     * Get a transaction by its id
     * operationId: AdminTransactionController_findOneById
     * Request URI: /api/admin/transactions/{id}
     */
    AdminTransactionController_findOneById: (
      params: Params$AdminTransactionController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminTransactionController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/transactions/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a transaction by its ID
     * Update a transaction by its ID
     * operationId: AdminTransactionController_updateOneById
     * Request URI: /api/admin/transactions/{id}
     */
    AdminTransactionController_updateOneById: (
      params: Params$AdminTransactionController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminTransactionController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/transactions/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of documents
     * Get a list of documents
     * operationId: AdminDocumentController_findAll
     * Request URI: /api/admin/documents
     */
    AdminDocumentController_findAll: (
      params: Params$AdminDocumentController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminDocumentController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/documents`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        perPage: { value: params.parameter.perPage, explode: false },
        page: { value: params.parameter.page, explode: false },
        type: { value: params.parameter.type, explode: false },
        term: { value: params.parameter.term, explode: false },
        adminStatus: { value: params.parameter.adminStatus, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of documents
     * Get a list of documents
     * operationId: AdminDocumentController_bulkDownload
     * Request URI: /api/admin/documents/bulk_download
     */
    AdminDocumentController_bulkDownload: (
      params: Params$AdminDocumentController_bulkDownload,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/admin/documents/bulk_download`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        perPage: { value: params.parameter.perPage, explode: false },
        page: { value: params.parameter.page, explode: false },
        type: { value: params.parameter.type, explode: false },
        term: { value: params.parameter.term, explode: false },
        adminStatus: { value: params.parameter.adminStatus, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a document by its id
     * Get a document by its id
     * operationId: AdminDocumentController_findOneById
     * Request URI: /api/admin/documents/{id}
     */
    AdminDocumentController_findOneById: (
      params: Params$AdminDocumentController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminDocumentController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/documents/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a document by its ID
     * Update a document by its ID
     * operationId: AdminDocumentController_updateOneById
     * Request URI: /api/admin/documents/{id}
     */
    AdminDocumentController_updateOneById: (
      params: Params$AdminDocumentController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminDocumentController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/documents/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update a documents by its ID
     * Update a documents by its ID
     * operationId: AdminDocumentController_bulkUpdate
     * Request URI: /api/admin/documents/bulk_update
     */
    AdminDocumentController_bulkUpdate: (
      params: Params$AdminDocumentController_bulkUpdate,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/admin/documents/bulk_update`;
      const headers = {
        'Content-Type': 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Run recognition on a document by its ID
     * Run recognition on a document by its ID
     * operationId: AdminDocumentController_runRecognition
     * Request URI: /api/admin/documents/{id}/run_recognition
     */
    AdminDocumentController_runRecognition: (
      params: Params$AdminDocumentController_runRecognition,
      option?: RequestOption
    ): Promise<void> => {
      const url =
        _baseUrl +
        `/api/admin/documents/${params.parameter.id}/run_recognition`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * List all company accounts
     * Get accounts
     * operationId: AdminAccountsController_getAccounts
     * Request URI: /api/admin/accounts
     */
    AdminAccountsController_getAccounts: (
      params: Params$AdminAccountsController_getAccounts,
      option?: RequestOption
    ): Promise<
      Response$AdminAccountsController_getAccounts$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/accounts`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Sync account
     * Sync account
     * operationId: AdminAccountsController_syncConnection
     * Request URI: /api/admin/accounts/{id}/sync
     */
    AdminAccountsController_syncConnection: (
      params: Params$AdminAccountsController_syncConnection,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/admin/accounts/${params.parameter.id}/sync`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of events
     * Get a list of events
     * operationId: AdminEventController_findAll
     * Request URI: /api/events
     */
    AdminEventController_findAll: (
      params: Params$AdminEventController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminEventController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/events`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        entityType: { value: params.parameter.entityType, explode: false },
        entityId: { value: params.parameter.entityId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a incorporation
     * Get a incorporation
     * operationId: AdminIncorporationController_findOneById
     * Request URI: /api/admin/incorporations
     */
    AdminIncorporationController_findOneById: (
      params: Params$AdminIncorporationController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminIncorporationController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/incorporations`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a incorporation
     * Update a incorporation
     * operationId: AdminIncorporationController_updateOneById
     * Request URI: /api/admin/incorporations
     */
    AdminIncorporationController_updateOneById: (
      params: Params$AdminIncorporationController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminIncorporationController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/incorporations`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update incorporation kyc
     * Approve incorporation kyc
     * operationId: AdminIncorporationController_approveKyc
     * Request URI: /api/admin/incorporations/approve
     */
    AdminIncorporationController_approveKyc: (
      params: Params$AdminIncorporationController_approveKyc,
      option?: RequestOption
    ): Promise<
      Response$AdminIncorporationController_approveKyc$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/incorporations/approve`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Fail incorporation kyc
     * Fail incorporation kyc
     * operationId: AdminIncorporationController_failKyc
     * Request URI: /api/admin/incorporations/fail
     */
    AdminIncorporationController_failKyc: (
      params: Params$AdminIncorporationController_failKyc,
      option?: RequestOption
    ): Promise<
      Response$AdminIncorporationController_failKyc$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/incorporations/fail`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of files
     * Get a list of files
     * operationId: AdminCompanyFileController_findAll
     * Request URI: /api/admin/company_files
     */
    AdminCompanyFileController_findAll: (
      params: Params$AdminCompanyFileController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyFileController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/company_files`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        type: { value: params.parameter.type, explode: false },
        personId: { value: params.parameter.personId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new file
     * Create a new file
     * operationId: AdminCompanyFileController_create
     * Request URI: /api/admin/company_files
     */
    AdminCompanyFileController_create: (
      params: Params$AdminCompanyFileController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyFileController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/company_files`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Remove a file by its id
     * Remove a file by its id
     * operationId: AdminCompanyFileController_delete
     * Request URI: /api/admin/company_files/{id}
     */
    AdminCompanyFileController_delete: (
      params: Params$AdminCompanyFileController_delete,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyFileController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/company_files/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a file by its ID
     * Update a file by its ID
     * operationId: AdminCompanyFileController_updateOneById
     * Request URI: /api/admin/company_files/{id}
     */
    AdminCompanyFileController_updateOneById: (
      params: Params$AdminCompanyFileController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminCompanyFileController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/company_files/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create a new onfido_data
     * Create a new onfido_data
     * operationId: AdminOnfidoDataController_create
     * Request URI: /api/admin/onfido_datas
     */
    AdminOnfidoDataController_create: (
      params: Params$AdminOnfidoDataController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminOnfidoDataController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/onfido_datas`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        personId: { value: params.parameter.personId, explode: false },
        companyId: { value: params.parameter.companyId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of taxes
     * Get a list of taxes
     * operationId: AdminTaxController_findAll
     * Request URI: /api/admin/taxes
     */
    AdminTaxController_findAll: (
      params: Params$AdminTaxController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminTaxController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/taxes`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new tax
     * Create a new tax
     * operationId: AdminTaxController_create
     * Request URI: /api/admin/taxes
     */
    AdminTaxController_create: (
      params: Params$AdminTaxController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminTaxController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/taxes`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a tax by its id
     * Get a tax by its id
     * operationId: AdminTaxController_findOneById
     * Request URI: /api/admin/taxes/{id}
     */
    AdminTaxController_findOneById: (
      params: Params$AdminTaxController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminTaxController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/taxes/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a tax by its ID
     * Update a tax by its ID
     * operationId: AdminTaxController_updateOneById
     * Request URI: /api/admin/taxes/{id}
     */
    AdminTaxController_updateOneById: (
      params: Params$AdminTaxController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminTaxController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/taxes/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of tasks
     * Get a list of tasks
     * operationId: AdminTaskController_findAll
     * Request URI: /api/admin/tasks
     */
    AdminTaskController_findAll: (
      params: Params$AdminTaskController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminTaskController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/tasks`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        topic: { value: params.parameter.topic, explode: false },
        companyId: { value: params.parameter.companyId, explode: false },
        onlyForUser: { value: params.parameter.onlyForUser, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new task
     * Create a new task
     * operationId: AdminTaskController_create
     * Request URI: /api/admin/tasks
     */
    AdminTaskController_create: (
      params: Params$AdminTaskController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminTaskController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/tasks`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update a task by id
     * Update a task by id
     * operationId: AdminTaskController_updateOneById
     * Request URI: /api/admin/tasks/{id}
     */
    AdminTaskController_updateOneById: (
      params: Params$AdminTaskController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminTaskController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/tasks/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Close a task by id
     * Close a task by id
     * operationId: AdminTaskController_close
     * Request URI: /api/admin/tasks/{id}/close
     */
    AdminTaskController_close: (
      params: Params$AdminTaskController_close,
      option?: RequestOption
    ): Promise<
      Response$AdminTaskController_close$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/tasks/${params.parameter.id}/close`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Dummy post for chat_messages
     * operationId: AdminChatMessageController_create
     * Request URI: /api/chat_messages
     */
    AdminChatMessageController_create: (
      params: Params$AdminChatMessageController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminChatMessageController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/chat_messages`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create a new taskFile
     * Create a new taskFile
     * operationId: AdminTaskFileController_create
     * Request URI: /api/admin/task_files
     */
    AdminTaskFileController_create: (
      params: Params$AdminTaskFileController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminTaskFileController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/task_files`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of billing_periods
     * Get a list of billing_periods
     * operationId: AdminBillingPeriodController_findAll
     * Request URI: /api/admin/billing_periods
     */
    AdminBillingPeriodController_findAll: (
      params: Params$AdminBillingPeriodController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminBillingPeriodController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/billing_periods`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new billing_period
     * Create a new billing_period
     * operationId: AdminBillingPeriodController_create
     * Request URI: /api/admin/billing_periods
     */
    AdminBillingPeriodController_create: (
      params: Params$AdminBillingPeriodController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminBillingPeriodController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/billing_periods`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a billing_period by its id
     * Get a billing_period by its id
     * operationId: AdminBillingPeriodController_findOneById
     * Request URI: /api/admin/billing_periods/{id}
     */
    AdminBillingPeriodController_findOneById: (
      params: Params$AdminBillingPeriodController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminBillingPeriodController_findOneById$Status$200['application/json']
    > => {
      const url =
        _baseUrl + `/api/admin/billing_periods/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove a billing_period by its id
     * Remove a billing_period by its id
     * operationId: AdminBillingPeriodController_delete
     * Request URI: /api/admin/billing_periods/{id}
     */
    AdminBillingPeriodController_delete: (
      params: Params$AdminBillingPeriodController_delete,
      option?: RequestOption
    ): Promise<
      Response$AdminBillingPeriodController_delete$Status$200['application/json']
    > => {
      const url =
        _baseUrl + `/api/admin/billing_periods/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a billing_period by its ID
     * Update a billing_period by its ID
     * operationId: AdminBillingPeriodController_updateOneById
     * Request URI: /api/admin/billing_periods/{id}
     */
    AdminBillingPeriodController_updateOneById: (
      params: Params$AdminBillingPeriodController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AdminBillingPeriodController_updateOneById$Status$200['application/json']
    > => {
      const url =
        _baseUrl + `/api/admin/billing_periods/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of bob accounts
     * Get a list of bob accounts
     * operationId: AdminBobAccountController_findAll
     * Request URI: /api/admin/bob/accounts
     */
    AdminBobAccountController_findAll: (
      params: Params$AdminBobAccountController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminBobAccountController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/bob/accounts`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        term: { value: params.parameter.term, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of bob daybooks
     * Get a list of bob daybooks
     * operationId: AdminBobDaybookController_findAll
     * Request URI: /api/admin/bob/daybooks
     */
    AdminBobDaybookController_findAll: (
      params: Params$AdminBobDaybookController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminBobDaybookController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/bob/daybooks`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of bob VATs
     * Get a list of bob VATs
     * operationId: AdminBobVATController_findAll
     * Request URI: /api/admin/bob/vats
     */
    AdminBobVATController_findAll: (
      params: Params$AdminBobVATController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminBobVATController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/bob/vats`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of bob Contacts
     * Get a list of bob Contacts
     * operationId: AdminBobContactController_findAll
     * Request URI: /api/admin/bob/contacts
     */
    AdminBobContactController_findAll: (
      params: Params$AdminBobContactController_findAll,
      option?: RequestOption
    ): Promise<
      Response$AdminBobContactController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/admin/bob/contacts`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        term: { value: params.parameter.term, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new bobContact
     * Create a new bobContact
     * operationId: AdminBobContactController_create
     * Request URI: /api/admin/bob/contacts
     */
    AdminBobContactController_create: (
      params: Params$AdminBobContactController_create,
      option?: RequestOption
    ): Promise<
      Response$AdminBobContactController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/admin/bob/contacts`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
  };
};
type ClientFunction<RequestOption> = typeof createClient<RequestOption>;
export type Client<RequestOption> = ReturnType<ClientFunction<RequestOption>>;
