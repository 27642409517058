import { FC, useState } from 'react';

import { Tabs } from 'antd';
import { Schemas } from '@api-client/generated/types';

import {
  CompanyDetailsEdit,
  CompanyDetailsInfo,
  CompanyDetailsPerson,
  CompanyDetailsPersonEdit,
} from '@entities';

import * as S from './styled';

type CompanyDetailsCardProps = {
  company: Schemas.AdminCompanyDto;
  onAfterEdit: () => void;
};

const CompanyDetailsCard: FC<CompanyDetailsCardProps> = ({
  company,
  onAfterEdit,
}) => {
  const [isVisibleModalDetailsEdit, setIsVisibleModalDetailsEdit] =
    useState(false);
  const [isVisibleModalPersonEdit, setIsVisibleModalPersonEdit] =
    useState(false);

  return (
    <S.Container vertical>
      <CompanyDetailsEdit
        company={company}
        open={isVisibleModalDetailsEdit}
        onCancel={() => setIsVisibleModalDetailsEdit(false)}
        onAfterEdit={onAfterEdit}
      />

      <CompanyDetailsPersonEdit
        company={company}
        open={isVisibleModalPersonEdit}
        onCancel={() => setIsVisibleModalPersonEdit(false)}
        onAfterEdit={onAfterEdit}
      />

      <S.Title>{company.name}</S.Title>

      <Tabs
        defaultActiveKey="details"
        size="large"
        items={[
          {
            key: 'details',
            label: 'Company details',
            children: (
              <CompanyDetailsInfo
                name={company.name}
                referenceId={company.referenceId}
                details={company.details}
                onEdit={() => setIsVisibleModalDetailsEdit(true)}
              />
            ),
          },
          {
            key: 'person',
            label: 'Contact person',
            children: (
              <CompanyDetailsPerson
                details={company.details?.contactPerson}
                onEdit={() => setIsVisibleModalPersonEdit(true)}
              />
            ),
          },
        ]}
      />
    </S.Container>
  );
};

export default CompanyDetailsCard;
