import { FC, type PropsWithChildren, createContext, useState } from 'react';
import { RouteObject } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { STORAGE_COMPANY_KEY, STORAGE_TOKEN_KEY } from '@constants';
// eslint-disable-next-line no-restricted-imports
import { getRoutes } from '@pages/getPaths';

type User = Schemas.User;
type Company = Schemas.Company;

type AccountContextProps = {
  isLogged: boolean;
  account: User | null;
  company: Schemas.AdminCompanyResponseDto | null;
  companyId: string | null;
  role?: string;
  accessToken: string | null;
  updateToken: (token: string) => void;
  setAccount: (account: User) => void;
  setCompany: (company: Schemas.AdminCompanyResponseDto) => void;
  clearAccount: () => void;
  routerPaths: RouteObject[];
};

export const AccountContext = createContext<AccountContextProps>(
  {} as AccountContextProps
);

export const AccountProvider: FC<PropsWithChildren> = ({ children }) => {
  const storageAccessToken = localStorage.getItem(STORAGE_TOKEN_KEY);

  const [accountDetails, setAccountDetails] = useState<User | null>(null);
  const [selectedCompany, setSelectedCompany] =
    useState<Schemas.AdminCompanyResponseDto>(
      JSON.parse(localStorage.getItem(STORAGE_COMPANY_KEY)!)
    );
  const [accessToken, setAccessToken] = useState<string | null>(
    storageAccessToken
  );

  const setAccount = (account: User) => setAccountDetails(account);

  const setCompany = (company: Schemas.AdminCompanyResponseDto) => {
    setSelectedCompany(company);

    if (company) {
      localStorage.setItem(STORAGE_COMPANY_KEY, JSON.stringify(company));
    }
  };

  const updateToken = (token: string) => {
    localStorage.setItem(STORAGE_TOKEN_KEY, token);

    setAccessToken(token);
  };

  const clearAccount = () => {
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(STORAGE_COMPANY_KEY);

    setAccountDetails(null);
    setAccessToken(null);
    setSelectedCompany({} as Company);
  };

  return (
    <AccountContext.Provider
      value={{
        isLogged: !!localStorage.getItem(STORAGE_TOKEN_KEY),
        account: accountDetails,
        company: selectedCompany,
        companyId: selectedCompany?.id || null,
        role: accountDetails?.adminRole,
        routerPaths: getRoutes(accountDetails?.adminRole) as RouteObject[],
        accessToken,
        updateToken,
        setAccount,
        setCompany,
        clearAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
