import { Button, Flex, Table, type TableColumnType, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminCompanyController_findAll } from '@api-client/generated/AdminCompanyController/findAll';
import { Schemas } from '@api-client/generated/types';
import { SearchControl } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

const { Title } = Typography;

const Companies: FC = () => {
  const [filterSearch, setFilterSearch] = useState(null);

  const { companyId, setCompany } = useAccount();

  const {
    data: companies,
    isPending: loading,
    refetch,
  } = useAdminCompanyController_findAll({
    params: {
      page: 1,
      term: filterSearch!,
    },
  });

  useEffect(
    () => {
      if (filterSearch || filterSearch === '') {
        refetch();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterSearch]
  );

  const columns: TableColumnType<Schemas.AdminCompanyResponseDto>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Company Name',
      width: 160,
      render: (name, record) => (
        <S.CompanyLink to={record.id}>{name}</S.CompanyLink>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Status',
      width: 160,
    },
    {
      key: 'actions',
      align: 'right',
      render: (_, record) =>
        companyId === record.id ? (
          <S.SelectedTag color="success" bordered={false}>
            Selected
          </S.SelectedTag>
        ) : (
          <Button
            type="primary"
            size="large"
            onClick={() => setCompany(record)}
          >
            Select
          </Button>
        ),
    },
  ];

  const handleSearch = useDebouncedCallback((value) => {
    setFilterSearch(value);
  }, 1000);

  return (
    <Flex gap={24} vertical>
      <Title>Companies</Title>

      <SearchControl onChange={(e) => handleSearch(e.target.value)} />

      <S.TableContainer>
        <Table
          rowKey={({ id }) => id}
          dataSource={companies}
          columns={columns}
          loading={loading}
          pagination={false}
          scroll={{ x: 720 }}
        />
      </S.TableContainer>
    </Flex>
  );
};

export default Companies;
